/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
//Campton
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

//Rubik
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
