/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */


.cui-tabs {
  height: 40px;
  display: flex;
  align-self: end;
}

.cui-tab {
  padding: 10px 24px;
  min-width: 148px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--colors-text-primary);
  border-bottom: 4px solid transparent;
  text-transform: capitalize;
  outline: 0;
  text-align: center;

  &:hover {
    transition: color 0.5s ease;
    color: rgba(255, 255, 255, 0.8);
  }

  &:active {
    transition: background-color 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active, &:hover, &:focus {
    outline: 0;
  }

  &--active {
    color: rgba(255, 255, 255, 1);
    border-bottom: 4px solid #A756FF;
  }
}
