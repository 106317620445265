/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */


.cui-error {
  display: grid;
  grid-row-gap: 24px;
  padding: 24px;
  text-align: center;

  &__header {
    font-family: "Campton", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--colors-text-secondary);
  }

  &__subheader {
    font-family: "Campton", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--colors-text-primary);
  }
}


.cui-empty {
  display: grid;
  grid-row-gap: 24px;
  padding: 24px;
  text-align: center;

  &__header {
    font-family: "Campton", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--colors-text-secondary);
  }

  &__subheader {
    font-family: "Campton", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--colors-text-primary);
  }

  &__action {
    display: grid;
    justify-self: center;
  }
}
