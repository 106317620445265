/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.cui-status {
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  height: 24px;
  padding: 0 12px;
  display: grid;
  align-content: center;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  font-family: "Rubik", sans-serif;

  border-radius: 2px;
  background-color: rgba(0, 162, 255, 0.1);

  width: 112px;

  &--badge {
    width: 24px;
    padding: 0;
  }

  &--wide {
    width: 160px;
  }

  &--default {
    color: var(--colors-text-primary);
    background-color: var(--colors-quaternary);
  }

  &--default-new {
    background: rgb(121, 121, 121);
    color: rgb(0, 0, 0)
  }

  &--primary {
    color: #00BCFF;
    background-color: rgba(0, 162, 255, 0.1);
  }

  &--published {
    background: #67F7AE;
    color: #111217;
  }

  &--secondary {
    color: #A0CECB;
    background-color: rgba(160, 206, 203, 0.5);
  }

  &--ternary {
    color: #FC6E51;
    background-color: rgba(252, 110, 81, 0.5);
  }

  &--quaternary {
    color: #E8CE4D;
    background-color: rgba(232, 206, 77, 0.1);
  }

  &--quinary {
    color: #BC95FF;
    background-color: rgba(188, 149, 255, 0.1);
  }

  &--success {
    color: #00bd56;
    background-color: rgba(0, 255, 100, 0.1);
  }

  &--error {
    color: #e02f41;
    background-color: rgba(218, 54, 72, 0.2);
  }

  &--empty {
    color: white;
    background: repeating-linear-gradient(-45deg, #ffb101, #ffb101 20px, #000 20px, #000 40px);
  }
}
