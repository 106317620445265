/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@mixin light-scroll() {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: lighten(#303953, 5%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken(#eaeaea, 25%);
  }
}
