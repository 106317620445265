/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

.cui-button {
  letter-spacing: 0;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 36px;
  max-width: 320px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  background-position: center;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all ease-in-out 0.2s;

  &:after:not(:disabled) {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.5) 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  }

  &--with-icon {
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.5rem;

    .icon {
      font-size: 20px;
    }

    span {
      white-space: nowrap;
      font-weight: inherit;
    }
  }

  &--with-icon-right {
    grid-template-columns: 1fr auto;
    grid-column-gap: 0.5rem;

    .icon {
      font-size: 20px;
    }

    span {
      white-space: nowrap;
      font-weight: inherit;
    }
  }

  &--icon-right {
    grid-template-columns: 1fr auto;
    grid-column-gap: 0.5rem;

    .icon {
      font-size: 20px;
    }

    span {
      white-space: nowrap;
      font-weight: inherit;
    }
  }

  &--small {
    height: 32px;
    width: 108px;
    font-size: 10px;
    line-height: 16px;

    span {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &--medium {
    height: 40px;
    min-width: 160px;
    font-size: 12px;
    line-height: 16px;

    span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &--large {
    height: 48px;
    min-width: 160px;
    font-size: 12px;
    line-height: 16px;

    span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &--primary-old {
    color: #2BFBD6;
    border: 1px solid #2BFBD6;
    background-color: rgba(43, 251, 214, 0.1);

    &:hover {
      background: none;
    }
  }

  &--secondary {
    color: var(--colors-white);
    border: 0 solid var(--colors-grey);
    background-color: var(--colors-quaternary);

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--ternary {
    border: 1px solid var(--colors-button-cancel-background);
    background-color: rgba(192, 35, 63, 0.1);
    color: var(--colors-button-cancel-background);

    &:hover {
      background: none;
    }
  }

  &--full {
    color: var(--colors-white);
    border: none;
    background-color: #FF00F0;

    &:hover {
      background-color: #FF52FD;
    }
  }

  &--export {
    color: var(--colors-grey);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    background-color: transparent;
    text-transform: none;

    &:hover {
      color: var(--colors-white);
      border: 1px solid rgba(255, 255, 255, 0.6);
    }
  }

  &--primary {
    border-radius: 4px;
    color: var(--colors-white);
    border: 1px solid transparent;
    background-color: #7040FF;
    text-transform: none;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--rainbow {
    color: var(--colors-white);
    border: none;
    background: linear-gradient(to right, #564FFD, #8F1DFB, #E8A0FF);

    &:hover {
      opacity: 0.8;
    }
  }

  &--link {
    border: none;
    background: none;
    color: var(--colors-text-primary);

    &:hover {
      background-color: var(--colors-ternary);
      color: var(--colors-white);
    }
  }

  &--icon {
    border: none;
    background: none;
    padding: 0;
    background: rgba(255, 255, 255, 0.03);
    color: var(--colors-text-primary);
    height: 32px;
    width: 32px;

    .icon {
      font-size: 20px;
    }

    &:hover {
      background-color: var(--colors-ternary);
      color: var(--colors-white);
    }
  }

  &--icon-medium {
    height: 40px;
    width: 40px;

    .icon {
      font-size: 24px;
    }
  }

  &--icon-xs {
    height: 24px;
    width: 24px;

    .icon {
      font-size: 16px;
    }
  }

  &:disabled {
    opacity: var(--colors-button-disabled-opacity);
    cursor: not-allowed;
  }

  &:active, &:focus {
    outline: 0;
  }

  &--wide {
    width: unset;
  }

  &--simple {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 152px;
    height: 40px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 4px;
    border: none;
    color: #88888B;
    text-transform: none;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--color {
    display: grid;
    justify-content: center;
    align-content: center;
    background: linear-gradient(to right, #564FFD, #8F1DFB, #E8A0FF);
    border-radius: 4px;
    border: none;
    color: var(--colors-white);
    text-transform: none;
  }

  &--default {
    color: var(--colors-white);
    border: 1px solid var(--colors-white);
    background-color: transparent;

    &:hover {
      background: none;
    }
  }


  &--duplicate {
    color: #88888B;
    border: 1px solid #88888B;
    background-color: rgba(136, 136, 139, 0.1);

    &:hover {
      background: none;
    }
  }

  &--accent {
    color: #2BFBD6;
    border: 1px solid #2BFBD6;
    background-color: rgba(43, 251, 214, 0.1);

    &:hover {
      background: none;
    }
  }

  &--quaternary {
    color: #FF8C42;
    border: 1px solid #FF8C42;
    background-color: rgba(255, 140, 66, 0.1);

    &:hover {
      background-color: rgba(255, 140, 66, 0.2);
    }
  }

  &--promote {
    color: #DAB6FC;
    border: 1px solid #DAB6FC;
    background-color: rgba(218, 182, 252, 0.1);

    &:hover {
      background-color: rgba(218, 182, 252, 0.2);
    }
  }

  &--error {
    color: var(--colors-button-cancel-background);
    border: 1px solid var(--colors-button-cancel-background);
    background-color: rgba(192, 35, 63, 0.1);

    &:hover {
      background: none;
    }
  }

  &--edit {
    color: #88888B;
    border: 1px solid #88888B;
    background-color: rgba(136, 136, 139, 0.1);

    &:hover {
      opacity: 0.8;
    }
  }
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
