/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.drawer-panel {

  .mat-dialog-container {
    padding: 0;
    box-shadow: -4px 0px 10px 1px #000000;
  }

  &__backdrop + .cdk-global-overlay-wrapper {
    justify-content: flex-end !important;
  }
}


.mat-dialog-container {
  padding: 0;
}
