/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use '../breakpoints' as *;

$dialog-padding: 1rem 5rem;
$dialog-header-padding: 1rem 5rem 1rem 0;

.cos-dialog {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;

  &__header {
    border-bottom: 1px solid var(--colors-separator);
    background-color: var(--colors-background-header);
    display: grid;
    grid-template-columns: 5rem 1fr;
    justify-content: space-between;
    align-items: center;

    &--with-tabs {
      .cos-dialog {
        &__header-content {
          padding-bottom: 0;
        }
      }
    }
  }

  &__header-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: $dialog-header-padding;
  }

  &__body {
    padding: $dialog-padding;
    overflow-y: scroll;
    background-color: var(--colors-background-header);
  }

  &__footer {
    border-top: 1px solid var(--colors-separator);
    background-color: var(--colors-background-header);
    height: 72px;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $dialog-padding;
  }
}
