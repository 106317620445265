/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@import "breakpoints";

.kknow-article-preview {
  padding: 16px;
  font-family: 'Georgia', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4B4B4B;
  background-color: #FFF;
  border-radius: 34px;

  ul:not([dir=rtl]), ol:not([dir=rtl]) {
    margin-left: 2rem;
  }

  ul, ol {
    &[dir=rtl] {
      margin-right: 2rem;
    }

    li {
      position: relative;
    }
  }

  ul {
    li {
      list-style-type: disc;
      list-style-position: outside;
    }
  }

  ol {
    li {
      list-style-type: decimal;
      list-style-position: outside;
    }
  }

  s {
    text-decoration: line-through;
  }

  em {
    font-style: italic;
  }

  table {
    max-width: calc(100vw - 32px);
    overflow-x: auto;
    display: flex;

    td {
      padding: 5px 10px;
    }
  }

  // photo caption style
  figcaption {
    font-style: italic;
    font-size: 12px;
    text-align: center;
  }

  sup {
    font-size: 10px;
    vertical-align: 30%;
    text-decoration: none;
  }

  .photo__caption {
    font-style: italic;
    font-size: 12px;
    text-align: center;
  }

  iframe {
    display: grid;
    justify-self: center;
    width: 100%;
    min-height: 250px;
  }

  .ad-wrapper, .video-js {
    iframe {
      min-height: unset !important;
    }
  }

  video {
    display: grid;
    justify-self: center;
    width: 100%;
  }

  &__iframe {
    min-height: 240px;
  }

  div:not(.author):not(.g-box):not(.g-box *):not(.ad-wrapper):not(.ad-wrapper *):not(.video-js):not(.video-js *) {
    margin-bottom: 16px;
  }

  .gallery {
    max-height: 240px;

    @media (min-width: $breakpoint-md) {
      max-height: 500px;
    }

    div {
      margin-bottom: 0;
    }
  }

  .video-container {
    display: grid;
    justify-content: center;
  }

  div.wp-caption {
    max-width: calc(100vw - 32px) !important;
  }

  .video-player {
    width: 100%;
    height: auto;
    object-fit: fill;
  }

  .article-banner {
    display: flex;
    justify-content: center;

    &__img {
      min-width: auto !important;
      width: auto !important;
    }
  }

  img:not(.kknow-game-banner__image):not(.kknow-products-carousel__logo):not(.kknow-products-carousel__img):not(.article-banner__img) {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    object-fit: cover;

    @media (min-width: $breakpoint-md) {
      max-height: 500px;
      object-fit: contain;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1E1E1E;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #1E1E1E;
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E1E;
  }

  .wp-caption-text {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 14px;
    color: rgba(75, 75, 75, 0.5);
  }

  .article-header {
    display: grid;
  }

  .author {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #878787;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .date {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 16px;
  }

  .sponsor {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgb(149, 165, 166);
    margin-top: 16px;
  }

  strong {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: inherit !important;
  }

  .category {
    display: grid;
    justify-self: start;
    border-bottom: 2px solid #32DCFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    width: auto;
    text-transform: uppercase;
    color: #1E1E1E;
    margin-bottom: 10px;
  }

  .subheader {
    display: grid;
    grid-row-gap: 10px;
  }

  .copyright {
    font-style: italic !important;
    font-size: 12px !important;
  }

  .source {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #878787;
    text-decoration: underline;
  }

  .source-name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #4B4B4B;
  }

  p {
    margin: 16px 0;
  }

  a {
    color: #878787;
    text-decoration: underline;
  }
}
