/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use 'breakpoints' as *;
@import "cui-containers";

.cui-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--colors-background-header);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @include responsive-padding;
}
