/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

.cos-text {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 100%;

  &--primary {
    color: var(--colors-text-primary);
  }

  &--secondary {
    color: var(--colors-text-secondary);
  }

  &--invalid {
    color: var(--colors-text-invalid);
  }

  &--old {
    color: var(--colors-text-primary);
    text-decoration: line-through;
  }

  &--bold {
    font-weight: bold;
  }

  &--decorated {
    font-style: italic;
  }

  &--small {
    font-size: 12px;
    line-height: 16px;
  }

  &--medium {
    font-size: 14px;
    line-height: 24px;
  }

  &--large {
    font-size: 16px;
    line-height: 24px;
  }

  &--xl {
    font-size: 20px;
    line-height: 32px;
  }

  &--xxl {
    font-size: 26px;
    line-height: 40px;
  }

  &--header {
    font-size: 36px;
    line-height: 48px;
    color: #FFFFFF;
  }

  &--trim {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--prompt {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.cos-icon {
  &--primary {
    color: var(--colors-text-primary);
  }

  &--secondary {
    color: var(--colors-text-secondary);
  }

  &--invalid {
    color: var(--colors-text-invalid);
  }
}

.cos-copy {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 16px;
}

