/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1400px;
$breakpoint-xl: 1920px;
$breakpoint-xxl: 2560px;
$breakpoint-uhd: 3840px;
