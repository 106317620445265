/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */


.button {
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  font-family: "Campton", sans-serif;
  font-weight: 500;
  font-size: 12px;
  transition: 0.2s all ease-in-out;

  &--wide {
    width: 200px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    transition: 0.2s all ease-in-out;
  }

  &:active {
    transition: 0.2s all ease-in-out;
  }

  &--primary {
    background-color: #3D5AFF;
    color: var(--colors-white);
    border: none;

    &:hover {
      background-color: darken(#3D5AFF, 20%);
    }
  }

  &--secondary {
    background: none;
    color: #3D5AFF;
    border: 1px solid rgba(101, 114, 143, 0.2);

    &:hover {
      background-color: #eeeeee;
    }
  }

  &--ternary {
    background: none;
    color: #1ecd97;
    border: 2px solid #1ecd97;
    width: auto;
    border-radius: 40px;
    min-width: 100px;
    text-transform: none;
    transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);

    &:hover {
      color: var(--colors-white);
      background-color: #1ecd97;
    }
  }

  &--white {
    padding: 8px 16px;
    box-sizing: border-box;
    border: 1px solid var(--colors-grey);
    border-radius: 4px;
    background-color: var(--colors-quaternary);
    color: var(--colors-text-primary);
    transition: all ease-in-out 0.2s;

    &:hover {
      background-color: var(--colors-grey-primary);
      color: var(--colors-white);
      border-color: rgba(255, 255, 255, 0.9);
    }

    &:disabled {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: var(--colors-white) !important;
      border-color: rgba(255, 255, 255, 0.9) !important;
    }
  }

  &--transparent {
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--colors-text-primary);
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    &:hover {
      color: var(--colors-white);
      border-color: var(--colors-white);
    }
  }

  &--dark {
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    color: var(--colors-white);
    border: 1px solid var(--colors-grey) !important;
    background-color: var(--colors-quaternary);
  }

  &--neon {
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    color: #2BFBD6;
    border: 1px solid #2BFBD6 !important;
    background-color: rgba(43, 251, 214, 0.1);
  }

  &--flat {
    background-color: #2E3951;
    padding: 8px 20px;
    color: var(--colors-text-primary);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    border: none;

    &:hover {
      background-color: #3E4D6E;
    }
  }

  &--rainbow {
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.2s;
    border: 0;
    background: linear-gradient(to right, #564FFD, #8F1DFB, #E8A0FF);
    color: var(--colors-white);
  }
}

.button {
  &--with-icon {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(101, 114, 143, 0.2);
    color: #65728F;
    border-radius: 50%;
    padding: 0;

    &:hover {
      border: 1px solid rgba(101, 114, 143, 0.4);
      color: #65728F;
    }

    .mat-icon {
      font-size: 16px !important;
      transform: rotate(180deg);
      height: 17px !important;
    }
  }
}
