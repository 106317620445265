/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use 'breakpoints' as *;

@mixin responsive-padding {
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  @media (min-width: $breakpoint-xs) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @media (min-width: $breakpoint-sm) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @media (min-width: $breakpoint-md) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @media (min-width: $breakpoint-lg) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (min-width: $breakpoint-xl) {
    padding-right: 180px;
    padding-left: 180px;
  }

  @media (min-width: $breakpoint-uhd) {
    padding-right: 780px;
    padding-left: 780px;
  }
}

.cui-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include responsive-padding;
}
