/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "../../node_modules/video.js/dist/video-js.css";

@include mat.core();
$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  )
));
@include mat.all-component-themes($my-theme);

@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-zoom';

@import "reset";
@import "buttons";

@import "fonts";
@import "fixes";
@import "drawer";

@import "cui-buttons";
@import "cui-header";
@import "cui-footer";
@import "cui-list";
@import "cui-list-utils";
@import "cui-status";
@import "cui-containers";
@import "cui-tabs";

//cosmose UI
@import "cos/cos-text";
@import "cos/cos-multiline";
@import "cos/cos-dialog";

//Article styles
@import "provider-article";

// Override
@import "material";

:root {
  --colors-text-primary: rgba(255, 255, 255, 0.5);
  --colors-text-secondary: #FFFFFF;
  --colors-text-accent: #889FFF;
  --colors-text-invalid: #FB2B50;

  --colors-button-success: rgba(43, 251, 214, 0.1);
  --colors-button-success-background: #2BFBD6;

  --colors-button-cancel: rgba(251, 43, 80, 0.2);
  --colors-button-cancel-background: #FB2B50;

  --colors-primary: #111217;
  --colors-secondary: #171B1F;
  --colors-ternary: rgba(255, 255, 255, 0.03);
  --colors-quaternary: rgba(255, 255, 255, 0.05);

  --colors-grey: rgba(255, 255, 255, 0.5);
  --colors-grey-primary: rgba(255, 255, 255, 0.1);
  --colors-grey-secondary: rgba(255, 255, 255, 0.2);
  --colors-text-grey: rgba(255, 255, 255, 0.2);
  --colors-datepicker: #111217;

  --colors-white: #FFFFFF;
  --colors-collapse-button: #586077;
  --colors-sidenav: #22252B;
  --colors-sidenav-container: #111217;
  --colors-error: #FB2B50;

  --colors-border-primary: rgba(255, 255, 255, 0.5);
  --colors-border-secondary: #ffffff;
  --colors-border-ternary: rgba(255, 255, 255, 0.2);

  --colors-background-disabled: rgba(0, 0, 0, 0.1);

  --colors-dialog: #22252B;
  --colors-hint: #4C35FF;
  --colors-contrast: #ae00ff;

  --colors-permission-excluded: #A92759;
  --colors-permission-included: #0D8683;
  --colors-permission-default: #857BFF;

  --transition: all ease-in-out 0.2s;

  --colors-background-primary: #111217;
  --colors-background-secondary: #171B1F;
  --colors-background-ternary: #22252B;
  --colors-background-quaternary: #2D3137;
  --colors-background-error: rgba(218, 54, 72, 0.2);
  --colors-background-light: #FFFFFF;
  --colors-background-grey: rgba(255, 255, 255, 0.5);

  --colors-background-list-item: #22252B;
  --colors-background-header: #171B1F;
  --colors-background-footer: #171B1F;
  --colors-background-dialog: #22252B;

  --colors-separator: rgba(255, 255, 255, 0.2);

  --colors-new-region-select: #7A2BFF;
  --colors-new-toggle-checked: #7A2BFF;

  --colors-checkbox: #fff;
  --colors-checkbox-stroke: #000;

  --colors-new-image-border: rgba(255, 255, 255, 0.05);
  --colors-new-icon-button-background: rgba(0, 0, 0, 0.2);
  --colors-button-disabled-opacity: 0.5;

  --colors-status-success: #2BFBD6;
  --colors-status-success-background: rgba(0, 255, 212, 0.1);

  --colors-status-fail: #FB2B50;
  --colors-status-fail-background: rgba(251, 43, 80, 0.1);
  --colors-status-default: rgba(255, 255, 255, 0.05);
  --colors-status-default-background: rgba(255, 255, 255, 0.5);
  --colors-status-primary: #A0CECB;
  --colors-status-primary-background: rgba(160, 206, 203, 0.5);

  --list-row-gap: 4px;
  --list-item-padding: 16px;
  --list-item-border-radius: 2px;

  --action-buttons-gap: 20px;
  --colors-grey: #808080;

  --cropper-overlay-color: var(--colors-background-ternary);

  --border-radius: 4px;
  --separator: 1px solid rgba(255, 255, 255, 0.1);

  --mdc-list-list-item-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-separator-color: rgba(255, 255, 255, 0.5);
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.5) !important;
  --mdc-list-list-item-hover-label-text-color: var(--colors-white);
  --mdc-filled-text-field-hover-label-text-color: var(--colors-white);
  --mat-app-on-surface-variant: rgba(255, 255, 255, 0.5);

  --mdc-outlined-text-field-hover-label-text-color: var(--colors-white);
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  color: var(--colors-text-primary);
  background-color: var(--colors-primary);
  font-size: 16px;
}

* {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: inherit;
  outline: none;
}

//chrome autocomplete input color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//chrome autocomplete default font
input:-webkit-autofill::first-line {
  font-size: 16px !important;
}

.upload-dialog .mat-dialog-container {
  border-radius: 8px;
  box-shadow: none;
  background-color: var(--colors-dialog);
}

.mat-dialog-container {
  background-color: var(--colors-dialog);
  padding: 0 !important;
}

.centered {
  display: flex;
  justify-content: center;
}

.mat-autocomplete-panel {
  background-color: var(--colors-secondary);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: var(--colors-secondary);
}

.mat-checkbox-frame {
  border-color: var(--colors-border-primary);
}

.mat-snack-bar-container {
  margin: 64px 0 0 20px !important;
  padding: 20px !important;
}

.custom-snackbar {
  padding: 48px;
  border-radius: 4px;
  background-color: #7A2BFF;
  top: 200px;
  display: grid;
  justify-content: center;
  align-items: center;
  min-width: 50vw !important;
  max-width: 80vw !important;
}

.mat-form-field {
  color: var(--colors-text-secondary);
}

.required-for-drop-icon {
  color: var(--colors-error);
  cursor: pointer;
}

::ng-deep {
  .mat-tooltip.required-for-drop-icon {
    white-space: pre-wrap;
  }
}

p {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cui-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

gallery {
  background-color: var(--colors-background-ternary) !important;
}

.ngx-ic-cropper {
  .ngx-ic-move {
    //border: 32px solid rgba(204, 252, 228, 0.7) !important;
  }
}

.ngx-ic-move-active {
  border: 32px solid rgba(204, 252, 228, 0.7) !important;
}

.mat-gallery-dialog {
  background: rgba(0, 0, 0, 0.8);
}

.custom-menu {
  padding: 8px;
}

a {
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.loading-text {
  color: var(--colors-text-secondary);
}

.mat-error {
  font-size: 12px;
}

.mat-radio-outer-circle {
  border: white;
}

.mat-radio-inner-circle {
  border: white;
}

.mat-radio-container {
  border: white;
}

:root .my-custom-ngx-diff-theme {
  --ngx-diff-border-color: #888888;
  --ngx-diff-font-size: 1rem;
  --ngx-diff-font-family: Consolas, Courier, monospace;
  --ngx-diff-font-color: #000000;
  --ngx-diff-line-number-font-color: #484848;
  --ngx-diff-line-number-hover-font-color: #ffffff;
  --ngx-diff-selected-border-color: #ff8000;
  --ngx-diff-inserted-background-color: #9dff97;
  --ngx-diff-deleted-background-color: #ff8c8c;
  --ngx-diff-equal-background-color: #ffffff;
  --ngx-diff-margin-background-color: #dedede;
  --ngx-diff-line-number-width: 2rem;
  --ngx-diff-border-width: 1px;
  --ngx-diff-line-left-padding: 1rem;
  --ngx-diff-bottom-spacer-height: 1rem;
}

.tox-promotion {
  display: none;
}

.tox .tox-editor-header {
  display: grid;
  grid-template-columns: 1fr !important;
  z-index: 2
}

.mat-form-field {
  width: 100%;

  &__label {
    color: var(--colors-text-primary);
    font-size: 14px;
  }
}

.update-form-field {
  &--without-errors {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

.tox-tinymce {
  border-radius: 0 !important;
}

.lg-custom-thumbnails {
  &.lg-outer {
    width: auto;
    // Set space for the container to occupy thumbnails
    right: 225px;

    // Add some spacing on the left to match with the right hand side spacing
    left: 10px;

    .lg-thumb-outer {
      // Set the position of the thumbnails
      left: auto;
      top: 0;
      width: 225px;
      bottom: 0;
      position: fixed;
      right: 0;

      // Reset max height
      max-height: none;

      // Customize the layout (Optional)
      background-color: #999;
      padding-left: 5px;
      padding-right: 5px;
      margin: 0 -10px;
      overflow-y: auto;

      // Update transition values
      // By default thumbnails animates from bottom to top
      // Change that from right to left.
      // Also, add a tiny opacity transition to look better
      transform: translate3d(30%, 0, 0);
      opacity: 0;
      transition: transform 0.15s cubic-bezier(0, 0, 0.25, 1) 0s, cubic-bezier(
          0,
          0,
          0.25,
          1
      ) 0.15s;
    }

    &.lg-thumb-open {
      .lg-thumb-outer {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    // Add hove effect (Optional)
    .lg-thumb-item {
      filter: grayscale(100%);
      will-change: filter;
      transition: filter 0.12s ease-in, border-color 0.12s ease;

      &:hover,
      &.active {
        filter: grayscale(0);
        border-color: #545454;
      }
    }

    .lg-thumb {
      padding: 5px 0;
    }
  }
}

.mat-pseudo-checkbox-full:not(.mat-pseudo-checkbox-checked) {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
